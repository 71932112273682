/* eslint-disable */
import React from 'react';
import { LandingFoot } from '../foot/LandingFoot';
import LandingNav from '../nav/LandingNav';

const About = () => {
  return (
    <div>
      <LandingNav />
      <h2 className="head__y">About</h2>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni sequi
      tempore placeat tenetur nobis? Doloremque nemo veniam numquam quae
      possimus? Voluptates, veniam fugiat sed repudiandae doloremque laborum
      dicta maiores consectetur.
      <LandingFoot />
    </div>
  );
};

export default About;
/* eslint-enable */
